export default {
    "devarshee.solanki@ffun.com":
    {
        // auto: 1,
        // accountingAuto: 1,
        // hrAuto: 1,
        // marketingAuto: 1,
        // operationsAuto: 1,
        // rec: 1,
        // accountingRec: 1,
        // hrRec: 1,
        // marketingRec: 1,
        // operationsRec: 0,
        // used: 1,
        // accountingUsed: 1,
        // hrUsed: 1,
        // marketingUsed: 1,
        // operationsUsed: 1,
        // creditmaxx: 1,
        // accountingCreditmaxx: 1,
        // hrCreditmaxx: 1,
        // marketingCreditmaxx: 1,
        // operationsCreditmaxx: 1,
        // operationsOneearth: 0,
        // total: 1,
        master: 1

        // auto: 1,
        // hrAuto: 1,
        // rec: 1,
        // hrRec: 1,
        // used: 1,
        // hrUsed: 1,
        // auto: 1,
        // marketingAuto: 1,
        // rec: 1,
        // marketingRec: 1,
        // used: 1,
        // marketingUsed: 1,
        // creditmaxx: 1,
        // marketingCreditmaxx: 1,


    },
    "mark@ffun.com": {
        master: 1
    },
    "dean.fitzpatrick@ffun.com": {
        master: 1
    },
    // "Ruby.Beyer@ffun.com": {
    //     master: 1
    // },
    // "rachel.cerekwicki@ffun.com": {
    //     master: 1
    // }
    // ,
    "paddy.farrant@ffun.com": {
        master: 1
    },
    "rob@ffun.com": {
        master: 1
    },
    "robbie.tarique@ffun.com": {
        auto: 1,
        hrAuto: 1,
        rec: 1,
        hrRec: 1,
        used: 1,
        hrUsed: 1,
        creditmaxx: 1,
        hrCreditmaxx: 1,
    },
    // "Kevin.McPherson@ffun.com": {
    //     master: 1
    // },
    "beau.garrett@ffun.com": {
        master: 1
    },
    "colby.bergen@ffun.com":{
        master: 1
    },
    "hector.ruiz@ffun.com": {
        master: 1
    },
    "michelle.wishart@ffun.com": {
        master: 1
    },
    "naveen.kambham@ffun.com": {
        master: 1
    },
    // "Mark.Conner@ffun.com": {
    //     master: 1
    // },
    "dean.fitzpatrick@ffun.com": {
        master: 1
    }
    ,
    "brad.paquin@ffun.com": {
        master: 1
    },
    "randy.pratt@ffun.com":{
        master: 1
    },
    "kelly.pitka@ffun.com": {
        auto: 1,
        hrAuto: 1,
        rec: 1,
        hrRec: 1,
        used: 1,
        hrUsed: 1,
        creditmaxx: 1,
        hrCreditmaxx: 1,
    },
    "nicole.kjaerlively@ffun.com": {
        auto: 1,
        hrAuto: 1,
        rec: 1,
        hrRec: 1,
        used: 1,
        hrUsed: 1,
        creditmaxx: 1,
        hrCreditmaxx: 1,
    },
    "morgan.norbury@ffun.com": {
       auto: 1,
        hrAuto: 1,
        rec: 1,
        hrRec: 1,
        used: 1,
        hrUsed: 1,
        creditmaxx: 1,
        hrCreditmaxx: 1, 
    },
    "kyle.senger@ffun.com": {
        master: 1
    },
    "terseer.akombo@ffun.com": {
        auto: 1,
        hrAuto: 1,
        rec: 1,
        hrRec: 1,
        used: 1,
        hrUsed: 1,
        creditmaxx: 1,
        hrCreditmaxx: 1, 

    },
    "lucia.veliz@ffun.com":{
        auto:1,
        accountingAuto:1,
        marketingAuto:1,
        rec:1,
        accountingRec:1,
        marketingRec:1,
        used:1,
        accountingUsed:1,
        marketingUsed:1,
        creditmaxx:1,
        accountingCreditmaxx:1,
        marketingCreditmaxx:1,
    },
    "dolapo.oladoyin@ffun.com": {
        auto: 1,
        hrAuto: 1,
        rec: 1,
        hrRec: 1,
        used: 1,
        hrUsed: 1,
        creditmaxx: 1,
        hrCreditmaxx: 1,
    },
     "lucia.veliz@ffun.com": {
        auto: 1,
        marketingAuto: 1,
        rec: 1,
        marketingRec: 1,
        used: 1,
        marketingUsed: 1,
        creditmaxx: 1,
        marketingCreditmaxx: 1,
    },"caitlin.saull@ffun.com": {
        auto: 1,
        marketingAuto: 1,
        rec: 1,
        marketingRec: 1,
        used: 1,
        marketingUsed: 1,
        creditmaxx: 1,
        marketingCreditmaxx: 1,
    },
}

// Access types
//need to have the division type and only the access for which section
//  auto:1,
//  accountingAuto:0,
//  hrAuto:1,
//  marketingAuto:1,
//  operationsAuto:1,

//  rec:1,
//  accountingRec:1,
//  hrRec:1,
//  marketingRec:1,
//  operationsRec:0,

//  used:1,
//  accountingUsed:1,
//  hrUsed:1,
//  marketingUsed:1,
//  operationsUsed:1,

//  creditmaxx:1,
//  accountingCreditmaxx:1,
//  hrCreditmaxx:1,
//  marketingCreditmaxx:1,
//  operationsCreditmaxx:1,

//  operationsOneearth:0,

//  total:1,

//  master:1 //Only need to have this one to get access for all